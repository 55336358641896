@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.dialog{
    background-color: #000010;
     position: fixed;
     top:5%;
    //  left: 20%;
    z-index: 102;
    align-items: center;
      margin:0px 100px ;
    border-radius: 30px;
    // width: fit-content;
//  border:#000010
}
.padding{
  padding: 20px;
}
.padding-top{
  padding-top: 179px;
}
.white{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.align-end{
    display: flex;
    justify-content: flex-end;
}
.align-end-mobile{
    display: none;
}
.close {
    position: relative;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 2px solid currentColor;
    background-color: transparent;
    color: #000010;
    background-color: white;
    cursor: pointer;
    z-index: 2;
    z-index: 100;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 50%;
      height: 2px;
      background-color: currentColor;
      left: 50%;
      top: 50%;
      margin-left: -25%;
      margin-top: -1px;
      transform: rotate(-45deg);
    }
    &::before {
      transform: rotate(45deg);
    }
    &:hover {
      color: #02A9F7;
      transform: scale(1.05);
    }
  }
 
  @media screen and (max-width: 991px){
    .padding{
      padding: 0px;
    }
    .padding-top{
      padding-top: 0px;
    }
    .align-end{
      display: none;
    }
    .align-end-mobile{
      display: flex;
      justify-content: flex-end;
    }
    .close{
      height: 30px;
      width:30px;
    }
    .dialog{
      z-index: 100;
      overflow-y: scroll;
      width:100vw;
      position: relative;
      margin:0px;
    }
  }
  